import client from '@/api/AuthService/_authServiceClient'
import convertApiUser from '@/utils/helpers/convert/api/user'
import store from '@/store'

function logout() {
  return new Promise((resolve, reject) => {
    client
      .get('/user/logout')
      .then(({data}) => resolve(data))
      .catch(({response}) => reject(response.statusText))
  })
}

function isLogged(invokedBy) {
  return new Promise((resolve, reject) => {
    client
      .get('/user_is_logged', {
        params: { 'invoked_by': invokedBy }
      })
      .then(() => resolve())
      .catch(response => reject(response))
  })
}

function changePassword(token, newPassword) {
  return new Promise((resolve, reject) => {
    client
      .post('/user/password_reset_confirm', {
        'reset_token': token,
        'new_password': newPassword.trim()
      })
      .then(() => resolve())
      .catch(({response}) => reject({
        statusText: response.statusText,
        error: response.data.error,
        passwordErrorMessages: response.data.password_errors || []
      }))
  })
}

function getUserSettings() {
  return new Promise((resolve, reject) => {
    client.get('/user/get_settings', {
      params: {
        domain: process.env.VUE_APP_PASSENGER_PORTAL_DOMAIN
      }
    })
      .then(({ data }) => {
        data.user = convertApiUser(data.user)

        localStorage.setItem('portalConfig', JSON.stringify(data.portal_config))

        store
          .dispatch('updateUser', data.user)
          .then(() => resolve(data))
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        }
        reject(error)
      })
  })
}

function anonymizeAcount(reason) {
  return new Promise((resolve, reject) => {
    client
      .post('/user/anonymize', {
        'reason': reason
      })
      .then(() => resolve())
      .catch(error => {
        if (error.response) {
          reject(error.response)
        }
        reject(error)
      })
  })
}

function userConsent() {
  return new Promise((resolve, reject) => {
    client
      .post('/user/consent')
      .then(() => resolve())
      .catch(error => {
        if (error.response) {
          reject(error.response)
        }
        reject(error)
      })
  })
}

function getPasswordRules(token) {
  return new Promise((resolve, reject) => {
    client.get('/user/password_rules', {
      params: { 'token': token }
    })
      .then((response) => resolve(response))
      .catch(response => reject(response))
  })
}

export default {
  logout,
  isLogged,
  changePassword,
  getUserSettings,
  anonymizeAcount,
  getPasswordRules,
  userConsent
}
