import { getAllTranslationsOfRoute } from '@/locales'

export default {
  path: 'account',
  component: () => import(/* webpackChunkName: "passenger-area" */ '@/views/Account/Account'),
  alias: getAllTranslationsOfRoute('account'),
  children: [
    {
      path: 'edit',
      name: 'account-edit',
      component: () => import(/* webpackChunkName: "passenger-area" */ '@/views/Account/AccountEdit'),
      alias: getAllTranslationsOfRoute('account-edit'),
    }
  ]
}
