import merge from 'lodash.merge'
import get from 'lodash.get'
import Base64 from 'Base64'

const USE_BASE64 = process.env.NODE_ENV === 'production'
export default class {
  _keyName;

  constructor(keyName) {
    this._keyName = keyName
  }

  getConfiguration() {
    if (false === this.isConfigurationExist()) {
      return null
    }

    let encoded = null
    try {
      encoded = JSON.parse(decodeURIComponent(Base64.atob(localStorage.getItem(this._keyName))))
    } catch (e) {
      return JSON.parse(localStorage.getItem(this._keyName))
    }

    return encoded
  }

  setConfiguration(obj) {
    if (!USE_BASE64) {
      return localStorage.setItem(this._keyName, JSON.stringify(obj))
    }

    return localStorage.setItem(this._keyName, Base64.btoa(encodeURIComponent(JSON.stringify(obj))))
  }

  mergeConfiguration(obj) {
    return this.setConfiguration(merge(this.getConfiguration(), obj))
  }

  isConfigurationExist() {
    return null !== localStorage.getItem(this._keyName)
  }

  clear() {
    localStorage.removeItem(this._keyName)
  }

  get(path) {
    const configuration = this.getConfiguration()

    if (null === configuration) {
      return null
    }
    
    return get(configuration, path, undefined)
  }
}
