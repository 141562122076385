import client from '@/api/AuthService/_authServiceClient'

function getServerSettings(domain) {
  return new Promise((resolve, reject) => {
    client.get('/sso/server_settings', { params: { domain } })
      .then(({data}) => resolve(data))
      .catch(error => {
        if (error.response) {
          reject(error.response)
        }
        reject(error)
      })
  })
}

export default {
  getServerSettings
}
