// JS and CSS
import './assets'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import {i18n} from './locales'
import {MessageBox} from 'uiv'
import moment from 'moment'
import '@/utils/typedef/core/MessageBoxConfirmOptions'
import '@/utils/typedef/core/NotificationOptions'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { FocusTrap } from 'focus-trap-vue'
import ErrorService from '@/utils/helpers/ErrorService'
import VueCookies from 'vue-cookies'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import FloatingVue from 'floating-vue'

Vue.config.productionTip = false

moment.locale(i18n.locale)

Vue.prototype.$notify = (data, dataDefault) => store.dispatch('notification/sendNotification', {
  'data': (data || null),
  'dataDefault': (dataDefault || null)
})
Vue.prototype.$alert = MessageBox.alert
//Vue.prototype.$notify = Notification.notify //OLD notify

/**
 * UIV MessageBox
 * @type {function(MessageBoxConfirmOptions): Promise}
 */
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

// Init ErrorService
Vue.config.errorHandler = (error) => ErrorService.onError(error)
Vue.prototype.$logger = {
  warning: message => ErrorService.onWarn(message),
  info: message => ErrorService.onInfo(message),
  debug: message => ErrorService.onDebug(message)
}

Vue.component('v-select', vSelect)
Vue.component('FocusTrap', FocusTrap)
Vue.component('v-calendar', Calendar)
Vue.component('v-date-picker', DatePicker)
Vue.use(FloatingVue)

Vue.use(VueCookies)

Vue.mixin({
  methods: {
    setPageTitle: function (pageTitle) {
      let title =  document.documentElement.getElementsByTagName('head').item(0).getElementsByTagName('title').item(0)
      title.innerText = pageTitle + ' | ' + this.$t('navbar.passenger_portal')
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
