// https://stackoverflow.com/a/75828547/2987610
export class WorkerInterval {
  worker = null
  constructor(callback, interval) {
    const blob = new Blob([`setInterval(() => postMessage(0), ${interval})`])
    const workerScript = URL.createObjectURL(blob)
    this.worker = new Worker(workerScript)
    this.worker.onmessage = callback
  }

  stop() {
    this.worker.terminate()
  }
}
