import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { configure } from 'vee-validate'
import { Settings as LuxonSettings } from 'luxon'
import moment from 'moment'
import json from '@/configuration.json'
import router from '@/router'

Vue.use(VueI18n)

export const availableLanguages = {
  sk_SK: { code: 'sk', iso: 'sk-SK', isoUnderscored: 'sk_SK', name: 'Slovensky'},
  en_GB: { code: 'en', iso: 'en-GB', isoUnderscored: 'en_GB', name: 'English'},
  de_DE: { code: 'de', iso: 'de-DE', isoUnderscored: 'de_DE', name: 'Deutsch'},
  en_CA: { code: 'en', iso: 'en-CA', isoUnderscored: 'en_CA', name: 'English'},
  fr_CA: { code: 'fr', iso: 'fr-CA', isoUnderscored: 'fr_CA', name: 'Français'},
  pl_PL: { code: 'pl', iso: 'pl-PL', isoUnderscored: 'pl_PL', name: 'Polski'},
  it_IT: { code: 'it', iso: 'it-IT', isoUnderscored: 'it_IT', name: 'Italiano'}
}

export let activeLanguages = initActiveLanguages(json.supported_languages.languages)
export let selectedLanguage = initDefaultLanguage()

export const i18n = new VueI18n({
  locale: selectedLanguage['isoUnderscored'],
  fallbackLocale: activeLanguages[0]['isoUnderscored'],
  messages:  loadLocaleMessages()//require('@/locales/translations/locale')
})

function loadLocaleMessages() {
  // noinspection JSUnresolvedFunction
  const locales = require.context(
    './translations',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// Configure VeeValidate messages
configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values)
})

LuxonSettings.defaultLocale = selectedLanguage['code']
moment.locale(selectedLanguage['code'])

function initActiveLanguages(active) {
  let languages = []

  for(let key in active) {
    let language = availableLanguages[active[key]]

    if(language !== undefined) {
      languages.push(language)
    } else {
      console.log(`Language ${active[key]} is undefined!`)
    }
  }

  if(!languages.length) {
    languages.push(availableLanguages['en_GB'])
  }

  return languages
}

function initDefaultLanguage() {
  if(json.language_initiation_domain && Object.values(json.language_initiation_domain).includes(window.location.host)) {
    let isoUnderscored = Object.keys(json.language_initiation_domain).find(key => json.language_initiation_domain[key] === window.location.host)
    let resultLang = activeLanguages.find(lang => lang.isoUnderscored === isoUnderscored)
    if (resultLang) {
      localStorage.setItem('selectedLanguage', resultLang.isoUnderscored)
      return resultLang
    }
  }

  let storedLang = localStorage.getItem('selectedLanguage')

  if (storedLang !== undefined && activeLanguages.find(elem => elem.isoUnderscored === storedLang)) {
    document.documentElement.setAttribute('lang', availableLanguages[storedLang].iso)
    return availableLanguages[storedLang]
  } else {
    let browserLangSupported = activeLanguages.find(lang => lang.iso === navigator.language || lang.code === navigator.language)
    let resultLang = browserLangSupported ? browserLangSupported : activeLanguages[0]
    localStorage.setItem('selectedLanguage', resultLang.isoUnderscored)
    document.documentElement.setAttribute('lang', resultLang.iso)

    return resultLang
  }
}

export function changeLanguage(lang) {
  if (typeof availableLanguages[lang] !== 'undefined') {
    selectedLanguage = availableLanguages[lang]
    localStorage.setItem('selectedLanguage', lang)
    i18n.locale = availableLanguages[lang]['isoUnderscored']
    LuxonSettings.defaultLocale = availableLanguages[lang]['code']
    moment.locale(availableLanguages[lang]['code'])
    router.replace({ path: localizeCurrentRoute(router.currentRoute) }).catch(()=>{})
    document.documentElement.setAttribute('lang', availableLanguages[lang].iso)

    return availableLanguages[lang]
  }

  return false
}

export function localizeCurrentRoute(to) {
  if(!i18n.te('route.path.' + to.name)) return to.path
  let localizedRoute = i18n.t('route.path.' + to.name)
  if(Object.keys(to.params).length) localizedRoute = addParamsToLocalizedRoute(localizedRoute, to.params)
  return localizedRoute
}

export function addParamsToLocalizedRoute(localizedRoute, params) {
  Object.keys(params).forEach(param => localizedRoute = localizedRoute.replace(`:${param}`, params[param]))
  return localizedRoute
}

export function getAllTranslationsOfRoute(routeName) {
  const locales = require.context(
    './translations',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const result = []
  const messages = {}
  locales.keys().forEach(key => {
    if (/en_GB|en_CA/.test(key)) return
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
      result.push(messages[locale].route.alias[routeName])
    }
  })
  return result
}

export function getEngPath(to) {
  if (to) {
    const currentLang = i18n.locale
    i18n.locale = 'en_GB'
    const enPath = addParamsToLocalizedRoute(i18n.t(`route.path.${to.name}`), to.params)
    i18n.locale = currentLang
    return enPath
  }
  return null
}

